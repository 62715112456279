.navigation {
    background-color: map-get($colors, 'space-blue');
    width: 100%;
    
        .navigation-items {
            margin: auto;
            max-width: 1200px;
            display: flex;
            justify-content: center;
            min-height: 100px;
    
            .navigation-item {
                margin-left: 5px;
                margin-top: 4px;
                margin-bottom: 4px;
                border-radius: 8px;
                width: 100%;
                position: relative;
                transition: background-color .2s;
    
                &:hover {
                    background-color: rgba(map-get($colors, 'white'), .15);
                }
                &:active {
                    background-color: map-get($colors, 'transparent-gray');
                }
                .navigation-item-text {
                    @extend %text;
                    height: max-content;
                    text-align: center;
                    font-family: 'Montserrat';
                    font-weight: bold;
                    font-size: 18px;
                }
                .navigation-item-link {
                    text-decoration: none;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    height: 100%;

                    .navigation-item-icon {
                        max-height: 38px;
                        margin: 0 3px; 
                    }
                }
                @each $name, $color, $filter in 
                ('yellow', map-get($colors, 'yellow'), map-get($filters, 'yellow')),
                ('green', map-get($colors, 'green'), map-get($filters, 'green')),
                ('red', map-get($colors, 'red'), map-get($filters, 'red')),
                ('sky-blue', map-get($colors, 'sky-blue'), map-get($filters, 'sky-blue')),
                ('blue', map-get($colors, 'ocean-blue'), map-get($filters, 'ocean-blue')),
                ('default', map-get($colors, 'white'), map-get($filters, 'white')),
                {
                    .nav-item-#{$name} {
                        color: #{$color};
                        
                        &.navigation-item-icon {
                            filter: $filter;
                        }
                    }
                }
                &.active {
                    background-color: map-get($colors, 'transparent-gray');
                }
                &:first-child {
                    margin-left: 0;
                }
            }
        }
    @include media-breakpoint-down(md) {
        .navigation-items {
            flex-wrap: wrap;
            justify-content: start;
            padding: 4px 4px 4px 2px;

            .navigation-item {
                margin: 0;
                min-height: 60px;
                width: 50%;

                .navigation-item-link {
                    flex-direction: row;
                    justify-content: start;

                    .navigation-item-text {
                        width: 100%;
                    }
                    .navigation-item-icon {
                        padding-left: 13px;
                    }
                }
            }
        }
    }
    @include media-breakpoint-down(xs) {
        .navigation-item-icon {
            width: 32px;
            height: 32px;
        }
    }
}
