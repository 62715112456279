.accordeon {
    width: 100%;
    .accordeon-item {
        position: relative;
        background-color: map-get($colors, 'half-blue');
        border-radius: 16px;
        margin-bottom: 24px;
        width: 100%;
        height: 100%;
        padding: 24px 32px 24px 24px;

        .accordeon-item-input {
            position: absolute;
            height: 100%;
            left: 0;
            top: 0;
            opacity: 0;
        }
        .accordeon-item-header {
            position: relative;
            width: max-content;
            max-width: 90%;
            .accordeon-item-label {
                position: relative;
                font-family: 'Montserrat';
                color: map-get($colors, 'white');
                font-size: 24px;
                font-weight: 600;
                width: content;
                max-width: 80%;
                border-bottom: 2px dotted map-get($colors, 'light-gray');
                z-index: 1;
                white-space: pre-wrap;
            }
            &:after {
                content: '';
                display: block;
                position: absolute;
                transition: transform .2s;
                top: 13px;
                right: -40px;
                height: 10px;
                width: 10px;
                margin-right: 10px;
                border-bottom: 3px solid map-get($colors, 'white');
                border-left: 3px solid map-get($colors, 'white');
                transform: rotate(-45deg);
            }
        }
        .accordeon-item-trigger {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: block;
            margin: 0;
            z-index: 2;
        }
        .accordeon-item-content {
            margin-left: -32px;
            padding: 0 32px;
            opacity: 0;
            max-height: 0px;
            transition: opacity .3s;
            -webkit-transition: opacity .3s;
            overflow-y: hidden;
        }
        .accordeon-item-content p:first-child {
            padding-top: 24px;
        }
        .accordeon-item-input:checked ~ .accordeon-item-content {
            transition: opacity .5s;
            max-height: 100%;
            opacity: 1;
        }
        .accordeon-item-input:checked ~ .accordeon-item-header:after {
            transform: rotate(135deg);
        }
    }
    @include media-breakpoint-down(sm) {
        .accordeon-item {
            padding: 18px 26px 18px 18px;
            .accordeon-item-header {
                &:after {
                    top: 9px;
                }
                .accordeon-item-label {
                    font-size: 20px;
                }
            }
            .accordeon-item-content {
                p, ul, ol, a, div, table {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
    }
}
