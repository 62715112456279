.review-form {
    background-color: transparent;
    font-family: "Montserrat";
    padding: 32px 0;
    position: relative;
    overflow: hidden;
    max-width: 450px;
    input,
    textarea {
        outline: none;
        background-color: transparent;
        box-shadow: none;
        border: 2px solid #5D5D67;
        border-radius: 7px;
        caret-color: #89899c;
        color: #89899c;
        min-height: 35px;
        padding: 5px;
        resize: none;
        transition: .2s;
        &:focus,
        &:hover {
            color: white;
            border-color: #89899c;
        }
    }
    .review-form-block-name {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        padding-bottom: 5px;
    }
    .review-form-header {
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
    }
    .review-form-inputs {
        display: flex;
        flex-wrap: wrap;
        padding-top: 20px;
        gap: 15px;
        .review-form-inputs-name {
            flex-grow: 1;
            input {
                width: 100%;
            }
        }
        .review-form-inputs-rating {
            position: relative;
            bottom: 0;
            .inputs {
                display: flex;
                flex-direction: row-reverse;
                align-items: flex-end;
                input {
                    width: 32px;
                    height: 32px;
                    opacity: 0;
                    position: relative;
                    cursor: pointer;
                    z-index: 3;
                }
                label {
                    position: relative;
                    line-height: 0;
                    bottom: 10px;
                    width: 0;
                    color: rgba(128, 128, 128, 0.26);
                    font-size: 30px;
                    transition: .2s ease-in-out;
                }
                input:checked~label,
                input:hover~label {
                    color: #FFE240;
                }
            }
        }
    }
    .review-form-message {
        padding: 20px 0 10px 0;
        textarea {
            width: 100%;
            max-width: 450px;
            min-height: 150px;
        }
    }
    .review-form-agreement-checkbox {
        position: absolute;
        width: 100%;
        z-index: 3;
        opacity: 0;
    }
    .review-form-agreement-checkbox:checked+.review-form-agreement-label:after {
        opacity: 1;
    }
    .review-form-agreement-checkbox:hover+.review-form-agreement-label:before,
    .review-form-agreement-checkbox:focus+.review-form-agreement-label:before {
        border-color: #89899c;
    }
    .review-form-agreement-label {
        position: relative;
        padding-left: 32px;
        max-width: 450px;
        color: white;
        &:before,
        &:after {
            position: absolute;
            left: 0;
            top: 4px;
            content: "";
            font-size: 22px;
            width: 24px;
            height: 24px;
            background: rgba(196, 196, 196, 0.5);
            border: 2px solid #5D5D67;
            border-radius: 3px;
            transition: .2s ease-in-out;
        }
        &:after {
            content: "✔";
            border: none;
            background: none;
            left: 3px;
            top: 0px;
            opacity: 0;
        }
    }
    .review-form-button {
        padding-top: 20px;
    }
}