.banner {
    font-family: 'Montserrat';
    height: 400px;
    width: 100%;
    overflow: hidden;
    border-radius: 16px;
    position: relative;

    .banner-img {
        position: absolute;
        object-fit: cover;
        object-position: center;
        height: inherit;
        width: 100%;
    }
    .banner-hero {
        position: relative;
        height: 100%;
        z-index: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: map-get($gradients, 'hero-gradient');
        }
        .banner-header, .banner-text, .slide-btn {
            position: relative;
            z-index: 1;
            padding-left: 32px;
        }
        .banner-header {
            display: block;
            font-weight: 900;
            font-size: 40px;
            height: max-content;
            line-height: 40px;
            padding-bottom: 7px;
        }
        .banner-text {
            display: block;
            line-height: 24px;
            width: 50%;
            margin-bottom: 90px;
            overflow-y: hidden;
        }
        .slide-btn {
            position: absolute;
            bottom: 8%;
        }
    }
}
@include media-breakpoint-down(lg) {
    .banner .banner-hero .banner-text {
        width: 95%;
    }
}
@include media-breakpoint-down(md) {
    .banner, .banner .banner-img {
        height: 200px;
    }
    .banner .banner-hero .banner-text {
        display: none;
    }
    .banner .banner-hero .banner-header {
        position: absolute;
        font-size: 25px;
        line-height: 30px;
        top: 0;
    }
}
@include media-breakpoint-down(sm) {
    .banner {
        border-radius: 0;
    }
}
@include media-breakpoint-down(xs) {
    .banner, .banner .banner-img {
        height: 150px;
    }
    .banner .banner-hero .banner-header {
        font-size: 27px;
        max-height: 90px;
        overflow-y: hidden;
    }
    .banner .banner-hero .slide-btn {
        transform: scale(.7) translate(-32px);
    }
}
