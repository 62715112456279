.review,
.reviews-box .review {
    background: rgba(255, 255, 255, 0.1);
    max-width: 374px;
    min-height: 180px;
    border-radius: 8px;
    padding: 24px 32px;
    font-family: "Montserrat";
    position: relative;
    height: auto;
    .review-head {
        display: flex;
        align-items: center;
        .review-head-avatar {
            width: 40px;
            height: 40px;
            margin-right: 8px;
            overflow: hidden;
            border-radius: 50%;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .review-head-user {
            p {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
            }
            .review-head-user-name {
                font-weight: 700;
                font-size: 18px;
                line-height: 22px;
            }
            .review-head-user-country {
                line-height: 16px;
            }
        }
    }
    .review-text {
        padding-top: 18px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        margin-bottom: 30px;
        text-align: justify;
    }
    .review-rate {
        position: absolute;
        bottom: 15px;
        display: flex;
        margin-top: 16px;
        align-items: center;
        .review-rate-value {
            display: none;
        }
        p {
            font-size: 22px;
            color: rgba(128, 128, 128, 0.26);
        }
        &.star-1 {
            p:nth-child(2) {
                color: #FFE240;
            }
        }
        &.star-2 {
            p:nth-child(2),
            p:nth-child(3) {
                color: #FFE240;
            }
        }
        &.star-3 {
            p:nth-child(2),
            p:nth-child(3),
            p:nth-child(4) {
                color: #FFE240;
            }
        }
        &.star-4 {
            p:not(:last-child) {
                color: #FFE240;
            }
        }
        &.star-5 {
            p {
                color: #FFE240;
            }
        }
    }
}

.reviews-box {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    justify-content: center;
    margin: 50px 0 24px 0;
}