%btn {
    border-radius: 8px;
    border: none;
    outline: none;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 600;
    letter-spacing: .21px;
    position: relative;
    height: 50px;
    padding: 0 32px;

    &:before, &:after {
        position: absolute;
        left: 0;
        top: 0;
        content: '';
        height: 100%;
        width: 100%;
        border-radius: 8px;
        opacity: 0;
        transition: .2s;
    }
    &:focus, &:hover, &:active {
        outline: none;
        box-shadow: none;
    }
    &:disabled, &.btn_disabled {
        pointer-events: none;
        &:after {
            background-color: rgba(map-get($colors, 'black'), .35);
            opacity: 1;
        }
    }
    .btn_icon, .btn_icon_right {
        display: inline-block;
        margin: 0 8px 3px 0;
        vertical-align: middle;
        width: 15px;
    }
    .btn_icon_right {
        margin: 0 0 2px 8px;
    }
    .btn_icon_play {
        position: relative;
        border: 8px solid transparent;
        border-right: 0px solid transparent;
        border-left: 14px solid map-get($colors, 'white');
        width: 0px;
    }
}
@mixin btn_color($background-clr, $content-clr, $filter-clr) {
    background-color: $background-clr;
    color: $content-clr;

    .btn_icon_play {
        border-left-color: $content-clr;
    }
    &.btn img.btn_icon, &.btn img.btn_icon_right, &.btn_social img.btn_icon { //-default svg color
        filter: map-get($filters, 'white');
    }
    &.btn.btn_yellow img.btn_icon, &.btn.btn_yellow img.btn_icon_right, &.btn_social.btn_yellow img.btn_icon { //-svg color for all yellow buttons
        filter: map-get($filters, 'half-blue');
    }
    &:hover {
        background-color: lighten($background-clr, 7%);
        transition: .3s;
        color: $content-clr;
    }
    &:active {
        background-color: darken($background-clr, 10%);
        transition: .1s;
    }
    &.btn_transparent {
        background-color: transparent;
        color: $background-clr;
        border: 2px solid $background-clr;

        .btn_icon_play {
            border-left-color: $background-clr;
            transition: .2s;
        }
        &.btn img.btn_icon, &.btn img.btn_icon_right, &.btn_social img.btn_icon {
            filter: map-get($filters , 'blue'); //-default
            filter: $filter-clr; //-for color classes
        }
        &:hover {
            border-color: lighten($background-clr, 10%);
        }
        &:active {
            border-color: darken($background-clr, 10%);
        }
        &:disabled, &.btn_disabled {
            pointer-events: none;
            border-color: map-get($colors, 'grass-green');
            color: map-get($colors, 'grass-green');
            
            &.btn img.btn_icon, &.btn img.btn_icon_right, &.btn_social img.btn_icon {
                filter: map-get($filters , 'grass-green');
            }
            .btn_icon_play {
                border-left-color: map-get($colors, 'grass-green');
            }
            &:after {
                background-color: transparent;
            }
        }
    }
}
.btn, .btn_social {
    @extend %btn;
    @include btn_color(map-get($colors, 'blue'), map-get($colors, 'white'), map-get($colors, 'white'));
}
.btn_sm {
    height: 34px;
    padding: 0 24px;

    &.btn_social {
        height: 34px;
        width: 34px;
    }
}
.btn_lg {
    height: 71px;
    padding: 0 40px;
    font-size: 16px;
    line-height: 100%;
    
    .item_play {
        margin-right: 13px;
    }
    &.btn_social {
        height: 71px;
        width: 71px;
    }
}
.btn_social {
    padding: 0;
    width: 50px;
    &:before {
        background-color: rgba(map-get($colors, 'white'), .15);
    }
    &:after {
        background-color: rgba(map-get($colors, 'black'), .20);
    }
    &:hover {
        &:before {
            opacity: 1;
        }
    }
    &:active {
        &:before {
            opacity: 0;
        }
        &:after {
            opacity: 1;
        }
    }
    &.btn_transparent:before, &.btn_transparent:after {
        background-color: transparent;
    }
    .btn_icon {
        margin: auto;
        height: 60%;
        width: 60%;
    }
}
.btn_inst_gradient {
    background: map-get($gradients, 'inst-gradient');
    color: map-get($colors, 'white');
}
@each $name, $background-clr, $content-clr, $filter-clr in 
    ('red', map-get($colors, 'red'), map-get($colors, 'white'), map-get($filters, 'red')),
    ('yellow', map-get($colors, 'yellow'), map-get($colors, 'half-blue'), map-get($filters, 'yellow')),
    ('green', map-get($colors, 'green'), map-get($colors, 'white'), map-get($filters, 'green')),
    ('true_red', map-get($colors, 'true-red'), map-get($colors, 'white'), map-get($filters, 'true-red')),
    ('blue', map-get($colors, 'blue'), map-get($colors, 'white'), map-get($filters, 'blue'))
    {
        .btn_#{$name} {
            @include btn_color($background-clr, $content-clr, $filter-clr);
        }
    }
