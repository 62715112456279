.main-content {
    position: absolute;
    top: 0;
    left: 0;
    background-color:map-get($colors, 'dark-blue');
    width: 100%;
    min-height: 100%;
}
.head-top {
    position: relative;
    width: 100%;

    &:before {
        content: '';
        position: absolute;
        display: block;
        height: 100%;
        width: 100%;
        background-color: map-get($colors, 'space-blue');
        opacity: .8;
    }
    .head-top-content {
        margin: auto;
        max-width: 1200px;
        display: flex;
        justify-content: space-between;

        .head-top-logo {
            position: relative;
            margin-top: auto;
            margin-bottom: auto;
            margin-right: auto;
            width: 100%;
            height: 100%;
            padding: 7px 10px;
            z-index: 5;

            img {
                position: relative;
                display: flex;
                justify-content: start;
                left: 0;
                height: 100%;
                max-height: 23px;
            }
        }
        .head-top-buttons {
            min-height: 56px;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;

            *:not(:last-child) {
                margin: 0 8px;
            }
            .head-top-button-help {
                padding: 5px;

                a {
                    position: relative;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    
                    .head-top-btn-help-img {
                        width: 24px;
                    }
                    .head-top-btn-help-text {
                        font-weight: 600;
                        color: map-get($colors, 'yellow');
                        font-size: 14px;
                        right: 0;
                        display: block;
                        padding: 0 3px 0 0;
                    }
                }
            }
        }
    }
}
.slider-box {
    padding: 24px 0;
}
.middle-content {
    margin: auto;
    max-width: 1200px;
    padding: 56px 15px 0 15px;
}
.btn-more-box {
    width: 100%;
    padding-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.card-box {
    margin: auto;
    width: 100%;
    max-width: 1200px;
    padding-top: 56px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .card-box-item {
        margin-left: 9px;
        margin-right: 9px;
        padding-bottom: 24px;
    }
}
.accordeon-box {
    margin: auto;
    max-width: 1200px;
    padding-top: 32px;
}
.footer-box {
    position: relative;
    bottom: 0;
    left: 0;
    background-color: map-get($colors, 'space-blue');
    min-height: 183px;
    margin-top: 32px;
    height: min-content;
    width: 100%;

    .footer {
        position: relative;
        padding-top: 39px;
        height: auto;
        max-width: 1200px;
        margin: auto;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;

        .footer-information {
            min-width: max-content;
            width: 50%;
            display: flex;
            padding-left: 20px;
            .footer-information-img {
                height: 64px;
                width: 64px;
            }
            .footer-information-texts {
                padding: 0 0 20px 20px;

                .footer-information-text, .footer-license-info {
                    color: map-get($colors, 'white');
                    line-height: 21px;
                    max-width: 440px;
                }
                .footer-license-info {
                    padding-top: 18px;
                    color: map-get($colors, 'silver-gray');
                }
            }
        }
        .footer-buttons {
            position: relative;
            min-width: min-content;
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;

            .footer-button-contact-us {
                display: flex;
                margin-bottom: 10px;
                padding: 0 15px;
                width: content;
                text-decoration: none;
            }
            .footer-buttons-social {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                min-width: 40%;
            }
            .footer-buttons-social * {
                margin-left: 3px;
                margin-right: 3px;
            }
        }
    }
}
@include media-breakpoint-down(xs) {
    .head-top .head-top-content {
        justify-content: center;
        flex-wrap: wrap;

        .head-top-logo img {
            width: 100%;
        }
        .head-top-buttons {
            flex-wrap: wrap;

            * {
                padding-top: 3px;
                padding-bottom: 3px;
            }
        }
    }
    .footer-box .footer {
        padding-right: 0;
        padding-top: 15px;

        .footer-information {
            padding-left: 0;
            flex-direction: column;
            align-items: center;
            
            .footer-information-img {
                padding-bottom: 10px;
            }
        }
    }
}
@include media-breakpoint-down(md) {
    .slider-box {
        padding: 0;
    }
    .footer-box .footer .footer-information {
        min-width: min-content;
        width: auto;
    }
}
