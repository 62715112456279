@import '../../simply/app.css';
@import './font.scss';

.sticky-top {
    position: sticky;
    top: 0;
    z-index: 3;
}
.main-content {
    user-select: none;
    background-color: #0a031d;
    
    .table {
        margin: auto;
        width: 100%;
        overflow-x: auto;
        max-width: 800px;

        table {
            display: table;
            width: auto;
        }
    }
}
.footer-box {
    padding-bottom: 10px;
}
.navigation, .footer-box, .accordeon .accordeon-item {
    background: #170d2e;
}
.head-top {
    background-color: rgba(#170d2e, .5);

    .head-top-btn-help-text {
        white-space: nowrap;
    }
}
.card-box .card-box-item {
    margin: 0 7px;
}
.card-box .card-box-item:nth-child(1) {
    margin-left: 0;
}
.card-box .card-box-item:last-child {
    margin-right: 0;
}

.error-message {
    text-align: center;
    min-height: calc(100vh - 687px);
    
    .btn-more-box { margin-top: 40px; }
}
   
@media(max-width: 992px) {
    .error-message { min-height: calc(100vh - 478px); }
}
