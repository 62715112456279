table {
    display: block;
    overflow-x: auto;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    margin: auto;
    padding: 15px 0 15px 0;
    $border-light: 1px solid map-get($colors, 'grass-green');
    $border-dark: 1px solid map-get($colors, 'dark-gray');

    tbody {
        width: 100%;
        display: table;
    } 
    th, td {
        padding: 17px 16px 16px 32px;
        min-width: 60px;
    }
    tr:nth-child(odd) th, 
    tr:nth-child(odd) td {
        background-color: map-get($colors, 'black');
    }
    tr:nth-child(even) th, 
    tr:nth-child(even) td {
        background-color: map-get($colors, 'black-grass');
    }
    tr th {
        font-weight: 500;
        border-left: $border-light;
        border-bottom: $border-light;
        border-top: $border-light;
        background-color: map-get($colors, 'light-black');

        &:first-child {
            border-top-left-radius: 8px;
        }
        &:last-child {
            border-right: $border-light;
            border-top-right-radius: 8px;
        }
    }
    tr td {
        border-left: $border-dark;
        border-bottom: $border-dark;

        &:last-child {
            border-right: $border-dark;
        }
    }
    tr:last-child {
        td:first-child {
            border-bottom-left-radius: 8px;
        }
        td:last-child {
            border-bottom-right-radius: 8px;
        }
    }
}
