.swiper {
    max-height: 400px;

    .swiper-item {
        max-width: 67%;
    }
    .swiper-item-active {
        min-width: 67%;
    }
}

@include media-breakpoint-down(md) {
    .swiper, .swiper .swiper-item {
        height: 200px;
    }
}
@include media-breakpoint-down(sm) {
    .swiper, .swiper .swiper-item {
        height: 200px;
    }
    .swiper .swiper-item {
        max-width: 100%;
        width: 100%;
    }
}
@include media-breakpoint-down(xs) {
    .swiper, .swiper .swiper-item {
        height: 150px;
    }
}
