.card {
    width: 282px;
    min-height: 417px;
    overflow: hidden;
    position: relative;
    background-color: transparent;
    border-color: transparent;

    .card_box {
        height: 382px;
        position: relative;
        &:hover {
            &:after {
                opacity: 1;
            }
            .card_btn {
                opacity: 1;
                transition: .3s .1s ease; //-delayed fade
            }
        }
        &:after, .card_img {
            position: absolute;
            border-radius: 8px;
            width: 100%;
            height: inherit;
        }
        &:after {
            left: 0;
            top: 0;
            content: '';
            background-color: rgba(map-get($colors, 'black'), .45);
            opacity: 0;
            transition: .3s ease-out;
        }
        .card_img {
            object-fit: cover;
        }
        .card_btn {
            position: absolute;
            top: 31%;
            width: 100%;
            z-index: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            opacity: 0;
            transition: .3s ease-out; //-disappearance

            .card_btn_item {
                margin-bottom: 24px;
            }
        }
    }
    .card_name {
        font-family: "Montserrat";
        font-weight: 500;
        color: map-get($colors, 'white');
        font-size: 16px;
        padding-top: 16px;
        bottom: 0;
    }
}

@include media-breakpoint-down(sm) {
    .card {
        width: 141px;
        min-height: 209px;

        .card_box {
            height: 185px;
            
            .card_btn, &:after {
                opacity: 1;
            }
            .card_btn {
                transform: scale(.75);
                top: 16%;
                transition: none;

                .card_btn_item .btn {
                    padding-left: 30px;
                    padding-right: 30px;
                }
            }
        }
        .card_name {
            padding-top: 8px;
        }
    }
}
