%text {
    font-family: "Montserrat";
    font-weight: 400;
    color: map-get($colors, 'white');
    line-height: 32px;
    letter-spacing: 0.21px;
    margin: 0;
}
@mixin header ($font-size, $font-weight, $padding-bottom) {
    @extend %text;
    letter-spacing: 0;
    line-height: 44px;
    padding-top: 10px;
    font-size: $font-size;
    font-weight: $font-weight;
    padding-bottom: $padding-bottom;
}
@each $header, $font-size, $font-weight, $padding-bottom in 
    (h1, 40px, 900, 24px),
    (h2, 32px, 700, 20px),
    (h3, 24px, 600, 8px),
    (h4, 18px, 500, 6px),
    (h5, 16px, 500, 4px),
    (h6, 16px, 400, 4px),
    {
    #{$header} {
        @include header($font-size, $font-weight, $padding-bottom)
    }
}
a, ol, ul, p, table {
    @extend %text;
    font-size: 16px;
}
a {
    color: map-get($colors, 'island-blue');
    text-decoration: underline;
    &:hover {
        color: map-get($colors, 'island-blue');
    }
    &:visited {
        color: map-get($colors, 'steel-gray');
    }
}
ol, ul {
    padding-left: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin: 0;
    font-size: 16px;
    letter-spacing: 0.24px;
    ul li {
        list-style-type: disc;
    }
}
.txt_md {
    font-size: 14px;
}
.txt_sm {
    font-size: 12px;
}
@include media-breakpoint-down(sm) {
    a, ol, ul, p, table {
        font-size: 14px;
        line-height: 24px;
    }
}
